import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import Markdown from "../Markdown"

import "./block.scss"


const Block = ({ title, subtitle, description, link, isAlternate, isContact}) => (
  <div className={`
    block
    ${(isAlternate)? 'block--alternate' : ''}
  `}>
    <Markdown className="block__title">{title}</Markdown>
    <Markdown className="block__subtitle">{subtitle}</Markdown>
    <Markdown className="block__description">{description}</Markdown>

    {(link)? 
      <span>
        <a className="button" href={link} target="_blank" nofollow>Read More</a>
      </span>
    : null}
    {(isContact)? 
      <span className="block__links">
        <a className="block__link block__link--alt" href="https://www.linkedin.com/in/jslyonnais/" target="_blank" nofollow>linkedin.com/in/jslyonnais</a>
      </span>
    : null}
  </div>
)

Block.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  isAlternate: PropTypes.bool,
  isContact: PropTypes.bool
}

export default Block