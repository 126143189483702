import _ from "lodash"
import React from "react"
import PropTypes from "prop-types"
import Markdown from "../Markdown"

import "./intro.scss"

const Intro = ({ title, subtitle, tagline, tagJob }) => (
  <>
    <div className="intro__wraper">
      <Markdown className="intro__title | title">{`## ${title}`}</Markdown>
      <Markdown className="intro__subtitle">{subtitle}</Markdown>
      <Markdown className="intro__tagline">{tagline}</Markdown>

      {(!_.isEmpty(tagJob))? 
        <div className="intro__tags">
          {tagJob.map((x, i) => <span key={x}>{x}{(i < tagJob.length -1)? ' - ' : ''}</span>)}
        </div> 
      : null}
    </div>
  </>
)

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  tagJob: PropTypes.array
}

export default Intro