import React from "react"
import PropTypes from "prop-types"
import Marked from "marked"

const Markdown = ({ children, className }) => (
  <div 
    className={`markdown ${(className)? className : '' }`} 
    dangerouslySetInnerHTML={{__html: Marked(children)}} />
)

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Markdown