import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Header from "../components/Header"
import Intro from "../components/Intro"
import Block from "../components/Block"

import Logo from "../images/js-logo.svg"

const IndexPage = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const nameAndTitle = `${frontmatter.name} - ${frontmatter.job}`

  return (
    <>
      <Header siteTitle={nameAndTitle} />
      <Layout>
        <SEO 
          title={frontmatter.seo.title}
          description={frontmatter.seo.description}
        />
        <div className="logo">
          <img className="logo__image" src={Logo} alt={nameAndTitle} />
        </div>
        <Intro 
          title={frontmatter.title}
          subtitle={frontmatter.subtitle}
          tagline={frontmatter.tagline}
          tagJob={frontmatter.tagJob}
        />
        <Block 
          title={frontmatter.getInTouch.title}
          subtitle={frontmatter.getInTouch.subtitle}
          description={frontmatter.getInTouch.description}
          isAlternate
          isContact
        />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark {
      frontmatter {
        name
        job
        title
        subtitle
        tagline
        tagJob
        tagLove
        getInTouch{
          title
          subtitle
          description
        }
        lastArticle{
          title
          subtitle
          description
          link
        }
        seo {
          title
          description
        }
      }
    }
  }
`

export default IndexPage
